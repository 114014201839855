import styles from './FeaturedBanner.module.scss'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

export const FeaturedBanner: FC = () => {
  const { t } = useTranslation('featured_banner')

  return (
    <section className={ styles.featuredBanner__background }>
      <div className={ styles.featuredBanner__container }>
        <h1 className={ styles.featuredBanner__title }>
          { t('title') }

          <span className={ styles.featuredBanner__subtitle }>
            { t('subtitle') }
          </span>

          <small className={ styles.featuredBanner__description }>
            { t('description') }
          </small>
        </h1>

        <a
          className={ styles.featuredBanner__ctaButton }
          href="#featured-organizers"
        >
          { t('featured_banner_cta') }
        </a>

        <video
          className={ styles.featuredBanner__video }
          poster={ '/img/banner/wbsc-trophy-card.jpg' }
          loop={ true }
          autoPlay={ true }
          playsInline={ true }
          muted={ true }
          width={ 440 }
        >
          <source
            src={ '/img/banner/wbsc-trophy-card.mp4' }
            type={ 'video/mp4' }
          />
          <p>Copa Sacyr ASOBAL 2022 - Trofeo NFT oficial</p>
        </video>
      </div>
    </section>
  )
}
