import { CSSProperties, FC } from 'react'
import { Organizer } from '~/modules/Organizers/Domain/Organizer'
import styles from './FeaturedOrganizerCard.module.scss'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

interface Props {
  organizer: Organizer
}

export const FeaturedOrganizerCard: FC<Props> = ({ organizer }) => {
  const { t } = useTranslation('featured_organizers')

  return (
    <div className={ styles.featuredOrganizerCard__card }>
      <Link
        href={ `/organizers/${organizer.id}` }
        className={ styles.featuredOrganizerCard__link }
      >
        { t('show_info_button') }
      </Link>
      <div
        className={ styles.featuredOrganizerCard__banner }
        style={ {
          '--bannerUrl': `url(${organizer.bannerUrl})`,
        } as CSSProperties }
      >
        <object
          data={ organizer.avatarUrl ?? undefined }
          className={ styles.featuredOrganizerCard__avatarWrapper }
        >
          <img
            className={ styles.featuredOrganizerCard__avatar }
            src={ '/img/defaults/organizer.png' }
            alt={ t('card_avatar_image') }
          />
        </object>
      </div>
      <div className={ styles.featuredOrganizerCard__organizerName }>
        { organizer.name }
      </div>
    </div>)
}
