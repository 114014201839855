import { FC } from 'react'
import styles from './PolygonBanner.module.scss'

export const PolygonBanner: FC = () => {
  return (
    <img
      className={ styles.polygonBanner__image }
      src="/img/banner/polygon-banner.png"
      alt="Powered by Polygon"
      lang="en"
    />
  )
}
