import { Organizer } from '~/modules/Organizers/Domain/Organizer'
import GetFeaturedOrganizersResponseInterface from './GetFeaturedOrganizersResponseInterface'
import ApiOrganizerResponseTranslator from './ApiOrganizerResponseTranslator'

export class GetOrganizersResponse {
  static make (organizers: Organizer[]): GetFeaturedOrganizersResponseInterface[] {
    return organizers.map((organizer: Organizer) => ApiOrganizerResponseTranslator.responseFromDomain(organizer))
  }

  static transform (responses: GetFeaturedOrganizersResponseInterface[]): Organizer[] {
    return responses.map(
      (response: GetFeaturedOrganizersResponseInterface) => ApiOrganizerResponseTranslator.domainFromResponse(response))
  }
}
