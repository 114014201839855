import React from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons'
import styles from './index.module.scss'

interface Props {
  title: string
  gallery: string
  image?: string
}

export const FeaturedGalleryCard: React.FC<Props> = ({ title, image, gallery }) => {
  return (
    <Link
      className={ styles.featuredGalleryCard__link }
      href={ gallery }
      passHref={ true }
    >
      <div className={ styles.featuredGalleryCard__container }>
        <img
          className={ styles.featuredGalleryCard__avatar }
          src={ image !== undefined && image !== null && image !== '' ? image : '/img/defaults/user.png' }
          alt={ title }
        />

        <h1 className={ styles.featuredGalleryCard__title }>
          { title }
        </h1>

        <span className={ styles.featuredGalleryCard__icon }>
          <FontAwesomeIcon
            icon={ faArrowUpRight }
            size="2x"
          />
        </span>
      </div>
    </Link>
  )
}
