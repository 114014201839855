import { Organizer } from '~/modules/Organizers/Domain/Organizer'
import GetFeaturedOrganizersResponseInterface from './GetFeaturedOrganizersResponseInterface'
import { BigNumber } from 'ethers'

export default class ApiOrganizerResponseTranslator {
  public static responseFromDomain (organizer: Organizer): GetFeaturedOrganizersResponseInterface {
    return {
      name: organizer.name,
      id: organizer.id,
      avatar_url: organizer.avatarUrl,
      banner_url: organizer.bannerUrl,
      logo_url: organizer.logoUrl,
      place: organizer.place,
      featured_trophy_id: organizer.featuredTrophyId ? organizer.featuredTrophyId.toString() : null,
      brandHexColor: organizer.brandHexColor,
    }
  }

  public static domainFromResponse (response: GetFeaturedOrganizersResponseInterface): Organizer {
    // TODO: CHECK IF ID IS A VALID ID
    return new Organizer(
      response.id,
      response.name,
      response.avatar_url,
      response.banner_url,
      response.logo_url,
      response.featured_trophy_id ? BigNumber.from(response.featured_trophy_id) : null,
      response.place,
      response.brandHexColor
    )
  }
}
