import type { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import nextI18nextConfig from 'next-i18next.config'
import { DependencyInjector } from '~/injector/dependency.injector'
import { HtmlPageMetaContextService } from '~/components/HtmlPageMeta/HtmlPageMetaContextService'
import { OrganizerRepositoryInterface } from '~/modules/Organizers/Domain/OrganizerRepositoryInterface'
import GetFeaturedOrganizers from '~/modules/Organizers/Application/GetFeaturedOrganizers/GetFeaturedOrganizers'
import { HtmlPageMetaContextProps } from '~/components/HtmlPageMeta/HtmlPageMetaContextProps'
import GetFeaturedOrganizersResponseInterface
  from '~/modules/Organizers/Application/GetFeaturedOrganizers/GetFeaturedOrganizersResponseInterface'
import { NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { GetOrganizersResponse } from '~/modules/Organizers/Application/GetFeaturedOrganizers/GetOrganizersResponse'
import { HtmlPageMetaResourceService } from '~/components/HtmlPageMeta/HtmlPageMetaResourceService'
import { HtmlPageMeta } from '~/components/HtmlPageMeta/HtmlPageMeta'
import { FeaturedBanner } from '~/components/FeaturedBanner/FeaturedBanner'
import { FeaturedOrganizers } from '~/components/FeaturedOrganizers/FeaturedOrganizers'
import { Faq } from '~/components/Faq/Faq'
import { PolygonBanner } from '~/components/PolygonBanner/PolygonBanner'
import { FeaturedGalleries, FeaturedGalleryDto } from '~/components/FeaturedGalleries'

interface Props {
  htmlPageMetaContextProps: HtmlPageMetaContextProps
  organizers: GetFeaturedOrganizersResponseInterface[]
  page: {
    number: number
    size: number
  }
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const pageNumber = 1
  const pageSize = 4
  const croatianFootballFederationId = 'f7a9c3c2-bfab-4667-b5f4-f98491a84ce3'

  const organizerRepository: OrganizerRepositoryInterface = DependencyInjector.getInstance()
    .resolve('OrganizerRepositoryInterface')
  const getFeaturedOrganizersUseCase = new GetFeaturedOrganizers(organizerRepository)
  const featuredOrganizers = (await getFeaturedOrganizersUseCase.run()).filter(
    (organizer) => organizer.id !== croatianFootballFederationId
  )

  const locale = context.locale ? context.locale : nextI18nextConfig.i18n.defaultLocale

  const htmlPageMetaContextService = new HtmlPageMetaContextService(context)

  return {
    props: {
      // https://github.com/vercel/next.js/discussions/11209
      organizers: JSON.parse(JSON.stringify(featuredOrganizers)),
      page: { number: pageNumber, size: pageSize },
      htmlPageMetaContextProps: htmlPageMetaContextService.getProperties(),
      ...await serverSideTranslations(
        locale,
        [
          'user_tos',
          'app_menu',
          'faq',
          'featured_banner',
          'featured_galleries',
          'featured_organizers',
          'footer',
          'home',
          'trophies',
          'wallet_error_modal',
        ]
      ),
    },
  }
}

const HomePage: NextPage<Props> = (props: Props) => {
  const { t } = useTranslation('home')

  const [organizers] = useState(GetOrganizersResponse.transform(props.organizers))
  const galleries: FeaturedGalleryDto[] = [
    {
      name: 'BRUNO CETRARO',
      imageUrl: 'https://cdn.leverade.com/trophyverse/users/ae04da84-298a-4d67-9aa1-609a79346917@1709641938.jpeg',
      galleryUrl: 'https://museum.belegends.com/gallery/brunocetraro',
    },
    {
      name: 'ERWIN PÉREZ',
      imageUrl: 'https://cdn.leverade.com/trophyverse/users/485e7916-e11d-4966-9a44-228d9ffee37f@1712246700.png',
      galleryUrl: 'https://museum.belegends.com/gallery/erwinfeuchtmann',
    },
    {
      name: 'SHIRLEY MELÉNDEZ',
      imageUrl: 'https://cdn.leverade.com/trophyverse/users/db329b43-664e-4bb5-bc72-4cbc065d5fdc@1710799545.jpg',
      galleryUrl: 'https://museum.belegends.com/gallery/shirleymelz',
    },
  ]

  const htmlPageMetaUrlProps = (
    new HtmlPageMetaResourceService(t('home_page_title'), t('home_page_description'))
  ).getProperties()
  const htmlPageMetaProps = { ...props.htmlPageMetaContextProps, ...htmlPageMetaUrlProps }

  return (
    <>
      <HtmlPageMeta { ...htmlPageMetaProps } />
      <FeaturedBanner />
      <FeaturedGalleries featured={ galleries } />
      <FeaturedOrganizers organizers={ organizers } />
      <Faq />
      <PolygonBanner />
    </>
  )
}

export default HomePage
