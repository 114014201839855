import { FC } from 'react'
import { FeaturedOrganizerCard } from '~/components/FeaturedOrganizerCard/FeaturedOrganizerCard'
import styles from './FeaturedOrganizers.module.scss'
import { Organizer } from '~/modules/Organizers/Domain/Organizer'
import { useTranslation } from 'next-i18next'

interface Props {
  organizers: Organizer[]
}

export const FeaturedOrganizers: FC<Props> = ({ organizers }) => {
  const { t } = useTranslation('featured_organizers')

  const featuredOrganizerCards = organizers.map(
    (organizer) => <FeaturedOrganizerCard organizer={ organizer } key={ organizer.id }/>
  )

  return (
    <section
      id="featured-organizers"
      className={ styles.featuredOrganizer__container }
    >
      <h1 className={ styles.featuredOrganizer__headerTitle }>
        { t('header_title') }
        <small className={ styles.featuredOrganizer__headerSubtitle }>
          { t('header_subtitle') }
        </small>
      </h1>
      <div className={ styles.featuredOrganizer__list }>
        { featuredOrganizerCards }
      </div>
    </section>
  )
}
