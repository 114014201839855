import { BigNumber } from 'ethers'

export interface OrganizerInterface {
  id: string
  name: string
  avatarUrl: string | null
  bannerUrl: string | null
  logoUrl: string | null
  place: string | null
  featuredTrophyId: string | null
  brandHexColor: string
}

export class Organizer {
  /** Internal organizer id */
  public readonly id: string

  /** Name of the organizer */
  public readonly name: string

  /** Image URI of the organizer's avatar */
  public readonly avatarUrl: string | null

  /** Image URI of the organizer's banner */
  public readonly bannerUrl: string | null

  /** Image URI of the organizer's logo */
  public readonly logoUrl: string | null

  /** Organizer's place */
  public readonly place: string | null

  /** Organizer's featured trophy id */
  public readonly featuredTrophyId: BigNumber | null

  /** Organizer's brand color */
  public readonly brandHexColor: string

  constructor (
    id: string,
    name: string,
    avatarUrl: string | null,
    bannerUrl: string | null,
    logoUrl: string | null,
    featuredTrophyId: BigNumber | null,
    place: string | null,
    brandHexColor: string
  ) {
    this.id = id
    this.name = name
    this.avatarUrl = avatarUrl
    this.bannerUrl = bannerUrl
    this.logoUrl = logoUrl
    this.place = place
    this.featuredTrophyId = featuredTrophyId
    this.brandHexColor = brandHexColor
  }

  public toJSON (): OrganizerInterface {
    return {
      id: this.id,
      name: this.name,
      avatarUrl: this.avatarUrl,
      bannerUrl: this.bannerUrl,
      logoUrl: this.logoUrl,
      featuredTrophyId: this.featuredTrophyId === null ? null : this.featuredTrophyId.toString(),
      place: this.place,
      brandHexColor: this.brandHexColor,
    }
  }
}
