import React from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'next-i18next'
import { FeaturedGalleryCard } from './FeaturedGalleryCard'
import styles from './index.module.scss'

export interface FeaturedGalleryDto {
  name: string
  imageUrl: string
  galleryUrl: string
}

interface Props {
  featured: FeaturedGalleryDto[]
}

export const FeaturedGalleries: React.FC<Props> = ({ featured }) => {
  const { t } = useTranslation('featured_galleries')

  const cards = featured.map((f) => {
    return (
      <FeaturedGalleryCard
        key={ f.galleryUrl }
        title={ f.name }
        image={ f.imageUrl }
        gallery={ f.galleryUrl }
      />
    )
  })

  return (
    <section
      id="featured-galleries"
      className={ styles.featuredGalleries__container }
    >
      <div className={ styles.featuredGalleries__header }>
        <h1 className={ styles.featuredGalleries__header__title }>
          { t('header_title') }

          <small className={ styles.featuredGalleries__header__subtitle }>
            { t('header_subtitle') }
          </small>
        </h1>

        <Link
          className={ styles.featuredGalleries__header__link }
          href='/gallery'
          passHref={ true }
        >
          { t('header_see_all') } { }
          <FontAwesomeIcon
            icon={ faArrowUpRight }
          />
        </Link>
      </div>

      <div className={ styles.featuredGalleries__list }>
        { cards }
      </div>
    </section>
  )
}
