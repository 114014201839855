import { FC, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import styles from './Faq.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

export const Faq: FC = () => {
  const { t } = useTranslation('faq')
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(null)

  function changeOpenIndex (index: typeof openQuestionIndex): void {
    if (openQuestionIndex === index) {
      setOpenQuestionIndex(null)

      return
    }

    setOpenQuestionIndex(index)
  }

  return (
    <section className={ styles.faq__container }>
      <div className={ styles.faq__title }>
        { t('section_title') }
      </div>

      <div
        className={ `
          ${styles.faq__questionContainer}
          ${openQuestionIndex === 1 ? styles.faq__questionContainer_open : ''}
        ` }
        data-qa={ 'question-container' }
      >
        <span
          className={ styles.faq__questionTitle }
          onClick={ () => changeOpenIndex(1) }
          data-qa={ 'question-title' }
        >
          { t('question_1') }
          <FontAwesomeIcon
            className={ styles.faq__questionIcon }
            icon={ faAngleDown }
            rotation={ openQuestionIndex === 1 ? 180 : undefined }
          />
        </span>
        <div
          className={ `
            ${styles.faq__questionAnswer}
            ${openQuestionIndex === 1 ? styles.faq__questionAnswer_open : ''}
          ` }
          data-qa={ 'question-answer' }
        >
          <p className={ styles.faq__questionAnswer__para }>{ t('answer_1_1') }</p>
          <p className={ styles.faq__questionAnswer__para }>{ t('answer_1_2') }</p>
          <p className={ styles.faq__questionAnswer__para }>{ t('answer_1_3') }</p>
        </div>
      </div>

      <div
        className={ `
          ${styles.faq__questionContainer}
          ${openQuestionIndex === 2 ? styles.faq__questionContainer_open : ''}
        ` }
        data-qa={ 'question-container' }
      >
        <span
          className={ styles.faq__questionTitle }
          onClick={ () => changeOpenIndex(2) }
          data-qa={ 'question-title' }
        >
          { t('question_2') }
          <FontAwesomeIcon
            className={ styles.faq__questionIcon }
            icon={ faAngleDown }
            rotation={ openQuestionIndex === 2 ? 180 : undefined }
          />
        </span>
        <div
          className={ `
            ${styles.faq__questionAnswer}
            ${openQuestionIndex === 2 ? styles.faq__questionAnswer_open : ''}
          ` }
          data-qa={ 'question-answer' }
        >
          { t('answer_2') }
        </div>
      </div>
      <div
        className={ `
          ${styles.faq__questionContainer}
          ${openQuestionIndex === 3 ? styles.faq__questionContainer_open : ''}
        ` }
        data-qa={ 'question-container' }
      >
        <span
          className={ styles.faq__questionTitle }
          onClick={ () => changeOpenIndex(3) }
          data-qa={ 'question-title' }
        >
          { t('question_3') }
          <FontAwesomeIcon
            className={ styles.faq__questionIcon }
            icon={ faAngleDown }
            rotation={ openQuestionIndex === 3 ? 180 : undefined }
          />
        </span>
        <div
          className={ `
            ${styles.faq__questionAnswer}
            ${openQuestionIndex === 3 ? styles.faq__questionAnswer_open : ''}
          ` }
          data-qa={ 'question-answer' }
        >
          <Trans
            i18nKey={ t('answer_3') }
            components={ [
              <a
                className={ styles.faq__link }
                key="link-how-to-mint"
                // eslint-disable-next-line max-len
                href="https://leverade.medium.com/how-to-claim-your-digital-trophies-medals-or-certificates-with-an-email-wallet-step-by-step-3e6bf0e49c44"
                target="_blank"
                rel="noreferrer"
              />,
            ] }
          />
        </div>
      </div>
      <div
        className={ `
          ${styles.faq__questionContainer}
          ${openQuestionIndex === 4 ? styles.faq__questionContainer_open : ''}
        ` }
        data-qa={ 'question-container' }
      >
        <span
          className={ styles.faq__questionTitle }
          onClick={ () => changeOpenIndex(4) }
          data-qa={ 'question-title' }
        >
          { t('question_4') }
          <FontAwesomeIcon
            className={ styles.faq__questionIcon }
            icon={ faAngleDown }
            rotation={ openQuestionIndex === 4 ? 180 : undefined }
          />
        </span>
        <div
          className={ `
            ${styles.faq__questionAnswer}
            ${openQuestionIndex === 4 ? styles.faq__questionAnswer_open : ''}
          ` }
          data-qa={ 'question-answer' }
        >
          { t('answer_4') }
        </div>
      </div>
      <div
        className={ `
          ${styles.faq__questionContainer}
          ${openQuestionIndex === 5 ? styles.faq__questionContainer_open : ''}
        ` }
        data-qa={ 'question-container' }
      >
        <span
          className={ styles.faq__questionTitle }
          onClick={ () => changeOpenIndex(5) }
          data-qa={ 'question-title' }
        >
          { t('question_5') }
          <FontAwesomeIcon
            className={ styles.faq__questionIcon }
            icon={ faAngleDown }
            rotation={ openQuestionIndex === 5 ? 180 : undefined }
          />
        </span>
        <div
          className={ `
            ${styles.faq__questionAnswer}
            ${openQuestionIndex === 5 ? styles.faq__questionAnswer_open : ''}
          ` }
          data-qa={ 'question-answer' }
        >
          <Trans
            i18nKey={ t('answer_5') }
            components={ [
              <a
                className={ styles.faq__link }
                key="link-how-to-gallery"
                // eslint-disable-next-line max-len
                href="https://leverade.medium.com/how-to-create-your-athlete-gallery-and-showcase-your-digital-achievements-with-the-world-step-by-074c2930c26c"
                target="_blank"
                rel="noreferrer"
              />,
            ] }
          />
        </div>
      </div>
      <div
        className={ `
          ${styles.faq__questionContainer}
          ${openQuestionIndex === 6 ? styles.faq__questionContainer_open : ''}
        ` }
        data-qa={ 'question-container' }
      >
        <span
          className={ styles.faq__questionTitle }
          onClick={ () => changeOpenIndex(6) }
          data-qa={ 'question-title' }
        >
          { t('question_6') }
          <FontAwesomeIcon
            className={ styles.faq__questionIcon }
            icon={ faAngleDown }
            rotation={ openQuestionIndex === 6 ? 180 : undefined }
          />
        </span>
        <div
          className={ `
            ${styles.faq__questionAnswer}
            ${openQuestionIndex === 6 ? styles.faq__questionAnswer_open : ''}
          ` }
          data-qa={ 'question-answer' }
        >
          { t('answer_6') }
        </div>
      </div>
    </section>
  )
}
